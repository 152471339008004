<template>
    <!-- 登录 -->
    <div class="login">
        <div class="loginTitle">
            <img class="titleImg" src="../assets/logo.png" />
        </div>
        <div class="loginText">联通健康通信息服务平台</div>
        <div class="titleText">登录</div>
        <!-- 卡片 -->
        <div class="loginCard">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="54px" class="demo-ruleForm" hide-required-asterisk>
                <el-form-item prop="account">
                    <el-input @keyup.enter.native="loginFun" v-model="ruleForm.account" placeholder="请输入账号" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input @keyup.enter.native="loginFun" v-model="ruleForm.password" placeholder="请输入密码" show-password></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button :loading="loading" class="carButton" type="primary" @click="loginFun">登录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'login',
        data() {
            return {
                imgUrl: '',
                // 加载
                loading: false,
                ruleForm: {
                    account: '',
                    // admin
                    // yanglei
                    // testAcount  //特殊人员
                    password: '',
                    // 12345678yy
                    // qwer1234
                    // ymlt789
                },
                rules: {
                    account: [
                        {
                            required: true,
                            message: '请输入账号',
                            trigger: 'blur',
                        },
                    ],
                    password: [
                        {
                            required: true,
                            message: '请输入密码',
                            trigger: 'blur',
                        },
                    ],
                },
            }
        },
        created() {
  // https://unicom-mapi.ikanke.cn
        },
        methods: {
            loginEnter() {
                console.log(13)
            },
            // 点击 登录
            loginFun() {
                this.postLogin()
            },
            postLogin() {
                if (!this.ruleForm.account || !this.ruleForm.password) {
                    this.$message({
                        message: '请填写账号和密码！',
                        type: 'error',
                    })
                    return false
                }
                this.loading = true
                sessionStorage.setItem('accountName', this.ruleForm.account)
                let username = this.ruleForm.account
                let password = this.ruleForm.password
                this.$instance
                    .post('/v1/admin-user/login', {
                        username,
                        password,
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            // const userInfo = res.data.data.role
                            // const userToken = res.data.data.token
                            const {
                                role: userInfo,
                                token: userToken,
                                placeId,
                                // 添加场所-通行方式 选择2或3显示
                                isNeedAttendance,
                                // 特定人员
                                // 0普通  1特定账号
                                isGiveAccount,
                                code,
                            } = res.data.data
                            sessionStorage.setItem('userInfo', userInfo)
                            sessionStorage.setItem('userToken', userToken)
                            sessionStorage.setItem(
                                'isNeedAttendance',
                                isNeedAttendance
                            )
                            sessionStorage.setItem('isGiveAccount', isGiveAccount)
                            sessionStorage.setItem('myCode', code)
                            if(code===666){
                                this.$router.push('/content/my-password')
                            }else{
                                this.$router.push('/content/home')
                            }
                            if (userInfo === 2) {
                                sessionStorage.setItem('placeId', placeId)
                            }
                            this.loading = false
                        } else {
                            this.$message.error(res.data.message)
                            this.loading = false
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.loading = false
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .login {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(247, 246, 244);
        .loginTitle {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-top: 60px;
            .titleImg {
                width: 170px;
            }
        }
        // 标题
        .loginText {
            font-size: 24px;
            font-weight: bold;
            margin: 40px 0 60px;
        }
        // 登录
        .titleText {
            font-size: 18px;
        }
        .loginCard {
            width: 320px;
            padding: 20px;
            border-radius: 6px;
            & ::v-deep .el-form {
                .el-form-item__content {
                    margin-left: 0px !important;
                    .el-input__inner {
                        text-align: center;
                        padding: 0 15px;
                    }
                }
            }
            .carButton {
                width: 100%;
            }
        }
    }
</style>
